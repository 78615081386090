.table-responsive {
  min-height: 200px;
  max-height: 450px; /* Adjust as needed */
  overflow-y: auto;
}

thead th {
  position: sticky;
  top: 0;
  z-index: 1;
  background: #d8e2ef; /* Ensure background color matches your design */
}

.fixed-header {
  background: #d8e2ef !important;
}