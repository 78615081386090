.DateInput_input {
  min-height: calc(1.5em + 0.375rem + 6px);
  padding: 0.1875rem 0.75rem;
  font-size: .875rem;
  border-radius: 0.25rem;
}

.react-datepicker-wrapper {
  width: 100%;
}

// .form-control-sm {
//   min-height: calc(1.5em + 0.375rem + 2px);
//   padding: 0.1875rem 0.75rem;
//   font-size: .875rem;
//   border-radius: 0.25rem;
// }