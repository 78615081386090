#travel-booking-report {
  font-family: "Helvetica Neue", Helvetica, sans-serif;
  font-size: 1.1em;

  table {
    width: 100%;
    border-collapse: collapse;
  }

  table, tr, td {
    border: 1px solid #787676;
    padding: 5px;
  }

  .bd-primary {
    background-color: #0031a5 !important;
  }

  .none-border {
    border: none;
  }

  .vertical-top {
    vertical-align: top;
  }
}



