.print-form {
  font-family: "Helvetica Neue", Helvetica, sans-serif;
  font-size: 1.1em;

  table {
    width: 100%;
    border-collapse: collapse;
  }

  table, tr, td {
    border: 1px solid #e3e6f0;
    padding: 5px;
  }

  .none-border {
    border: none;
  }

  .vertical-top {
    vertical-align: top;
  }
}



