.editable-input {
  outline: none;
  min-height: 30px; /* Adjust as needed */
  margin-top: 10px;
}

.hashtags-container {
  display: flex;
  flex-wrap: wrap;
  margin: 10px; /* Adjust as needed */
  font-size: .8333333333rem;
}

.hashtag {
  background-color: #f0f0f0;
  color: #333;
  padding: 2px 6px;
  border-radius: 4px;
  margin-right: 5px; /* Adjust spacing between hashtags */
  margin-bottom: 5px; /* Adjust spacing between lines */
  cursor: pointer;
}

.hashtag:hover {
  background-color: #ccc;
}
